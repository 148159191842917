import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { audioState } from '../../audioState';
import { AudioUrl } from '../../Constants/Config';


function AudioContainer({AudioData}) {
    const [audioData, setAudioData] = useRecoilState(audioState);
    const [open, setOpen] = useState(audioData?.isMinimized ?? false);
    const audioRef = useRef(null);

    useEffect(() => {
    console.log('REACHED');

        console.log('container', audioData.isPlaying);
        console.log(audioData?.currentTime, 'ctime');

        setOpen(audioData?.isMinimized ?? false);
        console.log(audioData, 'audioState');
        if (AudioData?.AudioSecretKey) {
            const isYouTubeLink =
                AudioData.AudioSecretKey.includes('youtube.com') ||
                AudioData.AudioSecretKey.includes('youtube');
            const isZohoWorkdrive =
                AudioData.AudioSecretKey.includes('zohoexternal.com');

            const audioSrc = isYouTubeLink
                ? `https://www.youtube.com/embed/${AudioData.AudioSecretKey.split('v=')[1]}?autoplay=1`
                : isZohoWorkdrive
                  ? AudioData.AudioSecretKey
                  : `${AudioUrl}${AudioData.AudioSecretKey}`;
        }

    }, [audioData]);

    const handleExpand = () => {
        const currentTime = audioRef.current?.currentTime || 0;
        console.log('current time in container', currentTime);

        setAudioData((prevState) => ({
            ...prevState,
            isPlaying: true, 
            isMinimized: false,
            currentTime: currentTime // prevState.currentTime
        }));
    };
    const handleClose = () => {
        audioData?.handleCloseModal?.();
        setAudioData((prevState) => ({
            ...prevState,
            isPlaying: false, // Pause audio instead of resetting data
            isMinimized: false // Reset minimized state
        }));
        setOpen(false);
    };
    const isMinimized = audioData?.isMinimized;

    return (
        open && (
            <div
                style={{
                    position: 'fixed',
                    bottom: '60px',
                    right: '20px',
                    width: '150px', // Adjusted width
                    height: '50px', // Adjusted height
                    backgroundColor: '#f1f1f1',
                    borderRadius: '10px',
                    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                    padding: '5px',
                    display: audioData?.isMinimized ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}
            >
                {(audioData?.videoUrl) && (

                    <iframe
                        src={audioData?.videoUrl} // Use videoUrl first if available
                        ref={audioRef}
                        autoPlay 
                        controls
                        controlsList="nodownload"
                        style={{ width: '100%', height: '50px' }}
                        onLoadedMetadata={(e) => {
                            console.log('running i');
                            e.target.currentTime = audioData?.currentTime || 0; // Set audio position from state
                            
                        }}
                        onEnded={() =>
                            setAudioData((prev) => ({
                                ...prev,
                                isPlaying: false
                            }))
                        }
                    />

                )} 
                {(audioData?.audioSrc)&&(
                    <audio
                        src={audioData.audioSrc} 
                        ref={audioRef}
                        autoPlay={audioData?.isPlaying}
                        controls
                        controlsList="nodownload"
                        style={{ width: '100%', height: '50px' }}
                        onLoadedMetadata={(e) => {
                            console.log('running b');

                            e.target.currentTime = audioData?.currentTime || 0; // Set audio position from state
                        }}
                        onEnded={() =>
                            setAudioData((prev) => ({
                                ...prev,
                                isPlaying: false
                            }))
                        }
                        >
                    </audio>
                )}

                <button
                    onClick={isMinimized ? handleExpand : handleClose}
                    style={{
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer'
                    }}
                >
                    <i className="fas fa-expand-arrows-alt"></i>
                </button>
            </div>
        )
    );
}

export default AudioContainer;
