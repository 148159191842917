import React from "react";
import privacySvg from "../../img/privacy_policy.svg";
import { useNavigate } from "react-router-dom";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";

const Contact = () => {
  const redirect = useNavigate();
  const handleBackButtonPress = () => {
    redirect("/user-home");
  };
  useHardwareBackButton(0, handleBackButtonPress);
  return (
    <div className="flex h-screen flex-col">
      <div className="relative w-full overflow-x-scroll pt-[40px]">
        <div className="bg-[hsla(0, 0%, 100%, 0.9)] fixed top-0 z-[999] flex h-fit w-full justify-between bg-white text-center shadow-md">
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center bg-darkBrown text-white"
            }
            onClick={() => redirect("/user-home")}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <div className={"flex h-[42px]  items-center justify-center "}></div>
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center opacity-0"
            }
          ></button>
        </div>
      </div>
      <div className="container relative mx-auto flex h-screen flex-1  overflow-hidden px-4 pb-14 md:pb-0">
       
        <div className="w-full overflow-y-auto overscroll-none scrollbar-hide">
          <img
            src={privacySvg}
            alt={"terms and conditions"}
            className="mx-auto h-auto w-full max-w-[600px]"
          />
          <div className="mb-8">
            <h1
              id={"Interpretation"}
              className="mb-3 text-4xl font-semibold tracking-tight"
            >
             Contact Us

            </h1>
            <p className="mb-3 leading-7 tracking-wide">
            If you need assistance, please reach out:
            </p>
            <p className=" leading-7 tracking-wide">
            Legal Entity: ATHULYAM
            </p>
            <p className=" leading-7 tracking-wide">
            Registered Address: 7/64, Health Camp, Near Sub Post Office, Gudalur, The Nilgiris, Tamil Nadu 643211
            </p>
            <p className=" leading-7 tracking-wide">
            Phone: +91 9894798080
            </p>
            <p className=" leading-7 tracking-wide">
            Email: psathulyam@gmail.com
            </p>
            <p className="mt-3 leading-7 tracking-wide">
            We're here to help with any inquiries!
            </p>

           
          </div>
        </div>
      </div>
      <div className="sticky bottom-0">
        <CombinedFooter />
      </div>
    </div>
  );
};

export default Contact;
