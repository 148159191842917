import React from "react";
import privacySvg from "../../img/privacy_policy.svg";
import { useNavigate } from "react-router-dom";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";

const About = () => {
  const redirect = useNavigate();
  const handleBackButtonPress = () => {
    redirect("/user-home");
  };
  useHardwareBackButton(0, handleBackButtonPress);
  return (
    <div className="flex h-screen flex-col">
      <div className="relative w-full overflow-x-scroll pt-[40px]">
        <div className="bg-[hsla(0, 0%, 100%, 0.9)] fixed top-0 z-[999] flex h-fit w-full justify-between bg-white text-center shadow-md">
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center bg-darkBrown text-white"
            }
            onClick={() => redirect("/user-home")}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <div className={"flex h-[42px]  items-center justify-center "}></div>
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center opacity-0"
            }
          ></button>
        </div>
      </div>
      <div className="container relative mx-auto flex h-screen flex-1  overflow-hidden px-4 pb-14 md:pb-0">
       
        <div className="w-full overflow-y-auto overscroll-none scrollbar-hide">
          {/* <img
            src={privacySvg}
            alt={"terms and conditions"}
            className="mx-auto h-auto w-full max-w-[600px]"
          /> */}
          <div className="mb-8 mt-8">
            <h1
              id={"Interpretation"}
              className="mb-3 text-4xl font-semibold tracking-tight"
            >
              About Us

            </h1>
            <p className="leading-7 tracking-wide">
            The Prithvi is your all-in-one Ayurveda companion, crafted to bring the ancient science of Ayurveda to the modern world. Designed for Ayurveda doctors, students, and enthusiasts, our platform delivers an immersive learning experience through expert-led video courses, eBooks, audio lessons, and interactive forums. With contributions from renowned practitioners, we aim to foster a vibrant community passionate about Ayurveda. Discover timeless wisdom, connect with experts, and enhance your journey in Ayurveda with The Prithvi.

            </p>
          </div>

          
        </div>
      </div>
      <div className="sticky bottom-0">
        <CombinedFooter />
      </div>
    </div>
  );
};

export default About;
