import './App.css';

import SpinnerComponent from './Components/Loaders/Spinner';
import Router from './Routes/Router';
import toast, { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearSession, getSession } from './lib/session';
import { Capacitor } from '@capacitor/core';
import {
    CheckMultiLogin,
    CheckMultiLoginError,
    GetUserError,
    LogoutMultiUser
} from './Redux/Authentication/action';
import { ApiUrl } from './Constants/Config';
import axios from 'axios';
import { useState } from 'react';
import UpdateNotifictationModal from './Pages/Login/UpdateNotifictationModal';

function App() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const session = getSession();
    const MultiLoginStatus = useSelector(
        (state) => state.AuthenticationReducer.CheckMultiLogin
    );

    const packageJson = require('../package.json');
    const [showModal, setShowModal] = useState(false);
    const closeModal = () => {
        setShowModal(false);
    };

    useEffect(() => {
        axios
            .get(ApiUrl + `appversion?user=0`)
            .then((response) => {
                console.log(response.data.Version);
                console.log(packageJson.version);
                if (packageJson.version !== response.data.Version) {
                    if (Capacitor.getPlatform() === 'android') {
                        setShowModal(true);
                    } else if (Capacitor.getPlatform() === 'web') {
                        window.location.reload();
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const handleLogout = () => {
        dispatch(CheckMultiLoginError());
        dispatch(GetUserError());
        clearSession();
        navigate('/');
    };
    // console.log('MultiLoginStatus',MultiLoginStatus);

    useEffect(() => {
        if (
            MultiLoginStatus &&
            MultiLoginStatus[0]?.IsValid == 0 &&
            session?.SecretKey
        ) {
            // console.log('MultiLoginStatus',MultiLoginStatus);
            dispatch(
                LogoutMultiUser({
                    SecretKey: session?.SecretKey,
                    UserId: session?.id
                })
            );
            handleLogout();
        }
    }, [MultiLoginStatus]);

    const fetchData = () => {
        // console.log('session.SecretKey', session.SecretKey);
        if (session && session.SecretKey) {
            dispatch(
                CheckMultiLogin({
                    SecretKey: session.SecretKey
                })
            );
        }
    };

    useEffect(() => {
        // const intervalId = setInterval(() => {
        // Call your function here
        fetchData();
        // }, 5000); // 5000 milliseconds = 5 seconds

        // Cleanup function to clear the interval when the component unmounts
        // return () => clearInterval(intervalId);
    }, []);
    // session?.SecretKey

    useEffect(() => {
        function handleContextMenu(event) {
            event.preventDefault(); // Prevent default right-click menu
        }

        // Add event listener to the document
        document.addEventListener('contextmenu', handleContextMenu);

        // Clean up function to remove the event listener when component unmounts
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);
    return (
        <div className="App">
            <Router />
            <SpinnerComponent />
            <UpdateNotifictationModal Show={showModal} Close={closeModal} />
            <Toaster />
        </div>
    );
}

export default App;
