import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useRecoilState } from 'recoil';
import { audioState } from '../../audioState';
import { AudioUrl } from '../../Constants/Config';

function AudioPlayModal({ showModal, handleCloseModal, AudioData }) {
    const [audioData, setAudioData] = useRecoilState(audioState);
    const audioRef = useRef(null);

    function extractIframeSrc(VideoUrl) {
        const match = VideoUrl.match(/src="([^"]+)"/);
        return match ? match[1] : null;
    }


    useEffect(() => {
        console.log("State", audioData);
        if (AudioData?.AudioSecretKey) {
            const isYouTubeLink =
                AudioData.AudioSecretKey.includes('youtube.com') ||
                AudioData.AudioSecretKey.includes('youtube');
            const isZohoWorkdrive =
                AudioData.AudioSecretKey.includes('zohoexternal.com');

            var audioSrc = isYouTubeLink
                ? `https://www.youtube.com/embed/${AudioData.AudioSecretKey.split('v=')[1]}?autoplay=1`
                : isZohoWorkdrive
                  ? AudioData.AudioSecretKey
                  : `${AudioUrl}${AudioData.AudioSecretKey}`;
            console.log(audioSrc,'src');
            const currentTime = audioData?.currentTime || 0;
            console.log(currentTime,"current");
            // let pausedTime = 0 ;
           
            setAudioData((prev) => {
                // audioRef.current?.currentTime = prev.currentTime ;
                // pausedTime = prev.currentTime ;
                return {
                   ...prev,
                    audioSrc: audioSrc,
                    isPlaying: true,
                    isMinimized: false,
                    currentTime: currentTime || 0,
                };
            })


            // setAudioData((prev) => ({
            //     ...prev,
            //     audioSrc: audioSrc,
            //     isPlaying: true,
            //     isMinimized: false,
            //     currentTime: prev.currentTime || 0,
            // }));
        }
    }, [AudioData, setAudioData]);



    const iframeString = AudioData?.VideoUrl;

    function extractUrl(iframeString) {
        if (!iframeString) return null;  // Add this check to avoid errors
        const regex = /src=["']([^"']+)["']/;
        const match = iframeString.match(regex);
        return match ? match[1] : null;
    }
    const url = extractUrl(iframeString);

    const handleMinimize = () => {
        const currentTime = audioRef.current?.currentTime || 0;
        console.log(currentTime,"hand");
        setAudioData((prev) => ({
            ...prev,
            isMinimized: true,
            currentTime: currentTime,
            videoUrl: url,
            isPlaying: true,
            isMuted: false
           
        }));
    };

    const handleClose = () => {
        setAudioData((prev) => ({
            ...prev,
            isPlaying: false,
            isMinimized: false
        }));

        handleCloseModal();
        window.location.reload();
    };

    return (
        <>
            {!audioData.isMinimized &&  (
                <Modal show={showModal} onHide={handleClose} centered>
                    <Modal.Body style={{ position: 'relative', padding: '20px' }}>
                        {AudioData?.VideoUrl ? (
                            <iframe 
                                ref={audioRef}

                                src={url} 
                                autoPlay={audioData.isPlaying}
                                style={{ width: '100%', height: '360px' }}
                                // onLoadedMetadata={(e) => {
                                //     e.target.currentTime = audioData?.currentTime || 0; // Set audio position from state
                                // }}
                            />
                        ) : audioData?.audioSrc ? (
                            <audio
                                ref={audioRef}
                                src={audioData.audioSrc}
                                autoPlay={audioData.isPlaying}
                                controls
                                onLoadedMetadata={(e) => {
                                    e.target.currentTime = audioData?.currentTime || 0; // Set audio position from state
                                }}
                                onEnded={() => setAudioData((prev) => ({
                                    ...prev,
                                    isPlaying: false
                                }))}
                                style={{ width: '100%', height: '60px' }}
                            />
                        ) : null}

                        <button
                            onClick={handleMinimize}
                            style={{ position: 'absolute', top: '10px', right: '10px' }}
                        >
                            <i className="fas fa-minus" />
                        </button>
                        <button
                            onClick={handleClose}
                            style={{ position: 'absolute', top: '10px', right: '50px' }}
                        >
                            <i className="fas fa-times" />
                        </button>
                    </Modal.Body>
                </Modal>
            )}
        </>
    );
}

export default AudioPlayModal;
