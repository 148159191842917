import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { GetDataUsingGrpId } from "../../Redux/UserHome/action";
import AllArticles from "./AllArticles";
import AllVideos from "./AllVideos";
import ImageGallery from "./ImageGallery";
import "../Css/AllGroup.css";
import AllEbooks from "./AllEbooks";
import AllAudios from "./AllAudios";
import BannerSlide from "../../Components/UserHome/BannerSlide";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";
function AllGroup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [VideoModal, setVideoModal] = useState(false)
  const [AudioModal, setAudioModal] = useState(false)
  const [OpenImage, setOpenImage] = useState(false)
  const handleBackButtonPress=()=>{
    if (VideoModal||AudioModal||OpenImage) {
      setVideoModal(false)
      setAudioModal(false)
      setOpenImage(false)
    } else {
      navigate(-1)
    }
    
  }
  useHardwareBackButton(0,handleBackButtonPress) 
  const { GroupId,TypeId } = useParams();
  const [searchTerm, setSearchTerm] = useState("");
  const GroupData = useSelector(
    (state) => state.UserHomeReducer.GetGroupData
  );
  console.log('GroupData',GroupData);

  useEffect(() => {
    if (GroupId) {
      dispatch(
        GetDataUsingGrpId({
         groupId: GroupId,
        }),
      );
    }
  }, [GroupId]);

const filteredArticles = GroupData?.[0]?.Data?.filter(
  (data) =>
  data.Heading.toLowerCase().includes(searchTerm.toLowerCase()) ||
  data.Keyword.toLowerCase().includes(searchTerm.toLowerCase())
  ) || [];
  

  return (
    <div className="ContentDisplayPage">
      <div className="Article-Modal"  style={{flex:'1 1 0%'}}>
        <div className=" home-slider ">
          <div className="page-header-con">
            <button className="backbtn" onClick={() => navigate(-1)}>
              <i className="fas fa-chevron-left"></i>
            </button>
          </div>
        </div>

        <h2 className="page-heading">{GroupData && GroupData[0]?.GroupName}</h2>
        <div className="container" style={{paddingBottom:'80px'}}>
        <div className="row Drug-text-input mt-3">
          <input
            type="text"
            className="form-control"
            placeholder={`Search...`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <button>
            <i className="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>

      {filteredArticles?.length === 0 ? (
        <p style={{ textAlign: 'center', color: 'gray', fontSize: '18px', marginTop: '20px' }}>
        No data available.
      </p>
      ) : (
        <>
          {TypeId == 1 && (
            <AllArticles filteredArticles={filteredArticles} />
          )}
          {TypeId == 2 && (
            <AllVideos filteredArticles={filteredArticles} VideoModal={VideoModal} setVideoModal={setVideoModal}/>
          )}
          {TypeId == 3 && (
            <ImageGallery filteredArticles={filteredArticles} OpenImage={OpenImage} setOpenImage={setOpenImage}/>
          )}
          {TypeId == 6 && (
            <AllEbooks filteredArticles={filteredArticles} />
          )}
          {TypeId == 4 && (
            <div className='mt-5'>
              <BannerSlide BannerData={filteredArticles} OpenImage={OpenImage} setOpenImage={setOpenImage}/>
            </div>
          )}
          {TypeId == 8 && (
          <AllAudios filteredArticles={filteredArticles}  AudioModal={AudioModal} setAudioModal={setAudioModal}/>
          )}
        </>
      )}
    </div>
      </div>
      
    <CombinedFooter/>
    </div>
  );
}

export default AllGroup;
