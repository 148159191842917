import React from "react";
import { ArticleImgUrl } from "../../Constants/Config";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation"; // Import navigation styles
import "swiper/css/autoplay";

// Import required modules
import { Pagination, Autoplay, Navigation } from "swiper/modules";

function BannerSlide({ BannerData }) {
  return (
    <>
      {BannerData?.length > 0 && (
        <div className="flex justify-center items-center py-8 px-4 sm:px-6 lg:px-8 bg-gray-50">
          <Swiper
            slidesPerView={1} // Show 3 slides
            arrow={true}
            spaceBetween={30} // Adjust space between slides
            centeredSlides={false} // Disable centering of slides
            initialSlide={0} // Start from the first slide
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3000, // Auto-slide every 3 seconds
              disableOnInteraction: false,
            }}
            navigation={true} // Enable navigation buttons
            modules={[Pagination, Autoplay, Navigation]} // Include Navigation module
            className="w-full max-w-7xl"
            breakpoints={{
              // When the viewport is >= 640px wide, show 1 slide
             
              640: {
                slidesPerView: 2,
              },
              // When the viewport is >= 768px wide, show 2 slides
              768: {
                slidesPerView: 2,
              },
              // When the viewport is >= 1024px wide, show 3 slides
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {BannerData.map((data, index) => (
              <SwiperSlide
                key={index}
                className="relative flex justify-center items-center"
              >
                <div className="w-full h-auto relative">
                  <img
                    className="rounded-lg shadow-lg w-full "
                    src={ArticleImgUrl + data.ImageUrl}
                    alt={data.Title || "Slide image"}
                    style={{
                      height: "246px", // Set height for images
                      width: "100%", // Use full width of the container
                      objectFit: "contain", // Use contain to fully display the image
                    }}
                  />
                  <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black via-transparent to-transparent p-4 text-white">
                    <h3 className="text-lg font-semibold">{data.Title}</h3>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
}

export default BannerSlide;
