import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import { FaArrowLeft, FaLock } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import { IoMdSend } from "react-icons/io";
import { IoCheckmarkDoneSharp, IoCheckmarkOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";
import { ApiUrl } from "../../Constants/Config";
import { chatTime } from "../../lib/helper";
import { getSession } from "../../lib/session";
import { getMessages, sendMessage } from "../../Redux/Chat/action";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { useDispatch, useSelector } from 'react-redux';



function SingleChat(props) {
  const [input, setInput] = useState("");
  const dispatch = useDispatch();
  const session = getSession();
  const [currentChat, setcurrentChat] = useState([]);
  const [isGuru, setIsGuru]= useState()
  const ChatInsertResponse = useSelector(

    (state) => state.ChatReducer.sendMessage,
  );
  const chatEndRef = useRef(null);

  const className =
    "w-max max-w-[80%] py-1 px-1.5 rounded-lg shadow-md text-sm text-black flex items-end divide-y";
    const redirect = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    e.target.reset();
    dispatch(
      sendMessage({
        chatid: props.selectedProfile?.Id,
        message: input,
        sendby: session.id,
      }),
    );
    setInput("");
  };

  const [MessageLength, setMessageLength] = useState(0);
  const latestMessageLength = useRef(MessageLength);
  const [PrevIsRead, setPrevIsRead] = useState(0);
  const LatestPrevIsRead = useRef(PrevIsRead);
  useEffect(() => {
    latestMessageLength.current = MessageLength;
    LatestPrevIsRead.current = PrevIsRead;
    // const guru = localStorage.getItem()
    const data = localStorage.getItem('authToken');
    const userData = JSON.parse(data);
    console.log("data ..",userData.isGuru);
    setIsGuru(userData?.isGuru)


  }, [MessageLength, PrevIsRead]);


  const handlecc =()=>{

   const data = localStorage.getItem('authToken');
  }
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          ApiUrl +
            `ChatMessage?cid=${props.selectedProfile?.Id}&uid=${session.id}`,
        );
        if (response.data.Error) {
          setcurrentChat("");
        } else {
          const newLength = response.data.length;
          const CurrentIsRead = response.data[response.data.length - 1].IsRead;
          if (
            newLength !== latestMessageLength.current ||
            CurrentIsRead !== LatestPrevIsRead.current
          ) {
            setcurrentChat(response.data);
            setMessageLength(newLength);
            setPrevIsRead(CurrentIsRead);
          }
          if (CurrentIsRead.Message === "CHAT_END") {
            window.location.reload();
            return;
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 800);
    return () => clearInterval(intervalId);
  }, [ChatInsertResponse]);

// api/Chat?ChatId=
  // end chat
  const handleEndChat = async () => {
    try {
      // Step 1: Send the message first
       dispatch(  
        sendMessage({
          chatid: props.selectedProfile?.Id,
          message: "CHAT_END",
          sendby: session.id,
        })
      );
  
      // Step 2: Call handleLimitCall to finalize chat ending

      setTimeout(async() => {
        console.log('reached');
        await handleLimitCall();
      }, 1000) ;
  
    } catch (error) {
      console.error("Error ending chat:", error);
      alert("There was an issue ending the chat.");
    }
  };
  
  const handleLimitCall = async () => {
    try {
      const response = await axios.get(
        ApiUrl + `Chat?ChatId=${props.selectedProfile?.Id}`
      );
  
      // Step 3: Handle response and finalize the chat end process
      if (response) {
        console.log(response);
        // alert('Chat ended');
        redirect('/chat');
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (error) {
      console.error("Error in handleLimitCall:", error);
      alert("There was an issue fetching chat data.");
    }
  };
  
  
  
  

  let currentDate = "";

  useEffect(() => {
    chatEndRef.current.scrollIntoView({ behavior: "smooth" });
  }, [currentChat]);

  return (
    <main className="min-h-screen bg-[url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png)] bg-cover bg-fixed">
      <header className="sticky top-0 z-30 flex items-center justify-center gap-3 bg-primaryTailwind px-2 py-2.5 text-white">
        <FaArrowLeft
          className="cursor-pointer text-xl"
          onClick={() => props.setSelectedProfile(null)}
        />
        <div className="mr-auto flex flex-col">
          <h4 className="py-2.5 font-semibold text-white">
            {props.selectedProfile?.UserName}
          </h4>
        </div>
        <HiDotsVertical className="mr-1 text-xl opacity-0" />
        {/* onClick={handleEndChat} */}
        {isGuru? (     
      <button onClick={handleEndChat} class="bg-blue-500 text-white font-bold py-2 px-4 rounded transition transform hover:bg-red-700 hover:scale-105 active:scale-100">
      End chat
    </button>
        ):(
          <span></span>
        )}
      </header>

      <section className="relative box-border flex flex-auto flex-col gap-3 overflow-y-scroll p-2 pb-44 scrollbar-hide">
        {currentChat && currentChat?.length > 0 ? (
          currentChat?.map((chat, index) => {
            let messageDate = new Date(chat.SysTime).toLocaleDateString();
            let shouldDisplayDate = messageDate !== currentDate;
            currentDate = messageDate;

            return (
              <React.Fragment key={index}>
                {shouldDisplayDate && (
                  <div className="mb-1 mt-2 text-center">
                    <div className="inline-block rounded-lg bg-gray-200 px-3 py-1">
                      <p className="text-xs font-bold text-gray-500">
                        {messageDate}
                      </p>
                    </div>
                  </div>
                )}
                <div
                  className={`${
                    chat.SentBy === session.id
                      ? "ml-auto bg-green-100"
                      : "bg-white"
                  } ${className} ${
                    chat.Message.length < 25 ? "flex-row gap-2" : "flex-col"
                  }`}
                >
                  <p className="break-all px-1.5 py-0.5">{chat.Message}</p>
                  <p className="text-xs text-black/40">
                    {chatTime(chat.SysTime)}
                    {chat.SentBy === session.id && chat.IsRead === 1 && (
                      <IoCheckmarkDoneSharp className="mx-1 inline-block text-lg text-sky-500" />
                    )}
                    {chat.SentBy === session.id && chat.IsRead === 0 && (
                      <IoCheckmarkOutline className="mx-1 inline-block text-lg " />
                    )}
                  </p>
                </div>
              </React.Fragment>
            );
          })
        ) : (
          <p className="mx-auto my-4 block w-11/12 rounded-lg bg-amber-200 p-2.5 text-center text-xs text-black/70 shadow-md">
            <FaLock className="inline" /> Messages and calls are end-to-end
            encrypted. No one outside of this chat, not even WhatsApp, can read
            or listen to them. Tap to learn more
          </p>
        )}
        <div ref={chatEndRef} style={{height:'70px'}}></div>
      </section>

      <form
        onSubmit={onSubmit}
        className="group fixed bottom-0 left-0 flex w-full items-center gap-2 bg-red-500/0 bg-[url(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png)] p-2 pt-0.5"
      >
        <div className="flex flex-grow items-center gap-3 rounded-full border bg-white p-2 text-black/50">
          <input
            name="msg"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className={twMerge(
              `titext`,
              `!border-0 border-none !py-1.5 text-black focus:outline-none`, 
            )}
            type="text"
            placeholder="Type a message"
          />
        </div>
        <button
          type="submit"
          disabled={!input}
          className="rounded-full bg-primaryTailwind p-3"
        >
          <IoMdSend className="text-xl text-white" />
        </button>
      </form>
    </main>
  );
}
 
export default SingleChat;
