import React, {
  Suspense,
  useCallback,
  useEffect,
  useState,
  Fragment,
  useRef,
} from "react";
import profileBg from "../../img/profile_background.svg";
import { CiEdit } from "react-icons/ci";
import { GetUserProfile } from "../../Redux/Authentication/action";
import { getSession } from "../../lib/session";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useWatch } from "react-hook-form";
import { twMerge } from "tailwind-merge";
import { IoMdCloudDone } from "react-icons/io";
import { zodResolver } from "@hookform/resolvers/zod";
import { forgotPasswordSchema, updateProfileSchema } from "../../lib/schema";
import { MdOutlineErrorOutline } from "react-icons/md";
import toast from "react-hot-toast";
import { Dialog, Transition } from "@headlessui/react";
import { FaExclamationTriangle } from "react-icons/fa";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import { ApiUrl } from "../../Constants/Config";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";

const Profile = () => {
  const session = getSession();
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();
  const redirect = useNavigate();
  const handleBackButtonPress=()=>{
    redirect('/user-home')
  }
  useHardwareBackButton(0,handleBackButtonPress) 
  const user = useSelector((state) => state.AuthenticationReducer.UserProfile);
  const spinner = useSelector((state) => state.Common.spinner);

  useEffect(() => {
    if (!session) {
      redirect("/");
    }
    dispatch(GetUserProfile({ id: session.id }));
  }, []);


  return (
    <div className="flex h-full min-h-screen flex-col">
      <div className="w-full overflow-x-scroll pt-[40px]">
        <div className="bg-[hsla(0, 0%, 100%, 0.9)] fixed top-0 z-[999] flex h-fit w-full justify-between bg-white text-center shadow-md">
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center bg-darkBrown text-white"
            }
            onClick={() => redirect('/user-home')}
          >
            <i className="fas fa-chevron-left"></i>
          </button>
          <div className={"flex h-[42px]  items-center justify-center "}></div>
          <button
            className={
              "flex h-auto w-[42px] items-center justify-center opacity-0"
            }
          ></button>
        </div>
      </div>
      <div className="container mx-auto h-full flex-1 py-8">
        <div
          className={`aspect-video relative mt-3 w-full rounded-md bg-green-400`}
        >
          <div
            className={`aspect-[19/6] h-fit max-h-[300px] w-full overflow-hidden rounded-xl`}
          >
            <img
              src={profileBg}
              alt="profile background image"
              className={"w-full bg-cover bg-fixed"}
            />
          </div>

          <div className="absolute bottom-0 left-1/2 h-[200px] w-[200px] -translate-x-1/2 translate-y-1/2 overflow-hidden rounded-full bg-white p-1">
            <div className="h-full w-full rounded-full bg-black"></div>
          </div>
        </div>
        <div className="flex h-fit flex-col justify-start space-y-5 pt-[120px] md:flex-row md:items-center md:justify-between md:space-y-0">
        <div className="w-60 font-semibold sm:text-base md:text-lg lg:text-xl">{user?.[0]?.Email}</div>

          <div className="flex items-center space-x-2">
            <button
              onClick={() => setIsEditing((ref) => !ref)}
              className="default-btn ml-0"
            >
              <CiEdit />
              {isEditing ? (
                <span>Cancel Editing</span>
              ) : (
                <span>Edit Profile</span>
              )}
            </button>
          </div>
        </div>
        {user ? (
          <EditProfileForm
            user={user?.[0]}
            isEditing={isEditing}
            session={session}
            setIsEditing={setIsEditing}
          />
        ) : (
          <span>loading....</span>
        )}
      </div>
      <CombinedFooter />
    </div>
  );
};

const EditProfileForm = ({ user, isEditing, session, setIsEditing }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const {
    handleSubmit,
    formState: { dirtyFields, errors },
    register,
    control,
  } = useForm({
    resolver: zodResolver(updateProfileSchema),
    defaultValues: {
      Name: user?.Name,
      Mobile: user?.Mobile,
      Password: user?.Password,
    },
  });

  const values = useWatch({
    control,
  });

  useEffect(() => {
    console.log("errors => ", errors);
  }, [errors]);

  useEffect(() => {
    // console.log('user => ', user);
    console.log(
      "values button is disabled? => ",
      !isEditing &&
        user.Mobile === values.Mobile &&
        user.Name === values.Name,
    );
    console.log(
      "values changed => ",
      user.Mobile !== values.Mobile ? "phone, " : null,
      user.Name !== values.Name ? "email." : null,
    );
    // console.log(
    // 	'values disabled => ',
    // 	(!isEditing && user?.Mobile !== values.phone) ||
    // 		user?.Email !== values.email,
    // );
  }, [isEditing, values]);

  const onSubmit = (data) => {
    console.log("data => ", data);

    try {
      const res = fetch( ApiUrl+ "UpdateUser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Id: session.id, ...data }),
      });

      toast.promise(
        res,
        {
          loading: "Loading",
          success: (data) => `Profile Successfully Updated!`,
          error: (err) => `Something went wrong!`,
        },
        {
          style: {
            minWidth: "250px",
          },
        },
      );
    } catch (error) {
      console.error("something went wrong...");
    } finally {
      setIsEditing(false);
    }
  };

  let showPassword=false

  return (
    <div className="my-12 " >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-8">
          <h1 className="mb-3 text-3xl font-semibold text-zinc-800">
            General Settings
          </h1>
          <div className="flex flex-col items-start lg:flex-row lg:items-start lg:space-x-6">
            <div className=" mb-4 flex flex-1 flex-col space-y-2 lg:!mb-0">
              <label className="text-lg font-semibold">Name</label>
              <div className="relative h-full">
                <input
                  type={"text"}
                  disabled={!isEditing}
                  placeholder={"Guest"}
                  {...register("Name")}
                  className={twMerge(
                    `titext`,
                    `font-medium disabled:!border-transparent disabled:!px-0`,
                    `${errors.Name ? "titext-error" : ""}`,
                  )}
                />
                {errors.Name && (
                  <MdOutlineErrorOutline
                    className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
                  />
                )}
              </div>
              {errors.Name && (
                <span className="text-sm font-semibold text-red-500">
                  {errors.Name.message}
                </span>
              )}
            </div>
            <div className=" mb-4 flex flex-1 flex-col space-y-2 lg:!mb-0">
              <label className="text-lg font-semibold">Phone</label>
              <div className="relative h-full">
                <input
                  type={"text"}
                  disabled={!isEditing}
                  placeholder={"99 99 99 99 99"}
                  {...register("Mobile")}
                  className={twMerge(
                    `titext`,
                    `font-medium disabled:!border-transparent disabled:!px-0`,
                    `${errors.Mobile ? "titext-error" : ""}`,
                  )}
                />
                {errors.Mobile && (
                  <MdOutlineErrorOutline
                    className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
                  />
                )}
              </div>

              {errors.Mobile && (
                <span className="text-sm font-semibold text-red-500">
                  {errors.Mobile.message}
                </span>
              )}
            </div>
            <div className=" mb-4 flex flex-1 flex-col space-y-2 lg:!mb-0">
              <label className="text-lg font-semibold">Password</label>
              <div className="relative h-full">
                <input
                  type={showPassword ? "text":"password"}
                  disabled={!isEditing}
                  placeholder={"* * * * *"}
                  {...register("Password")}
                  className={twMerge(
                    `titext`,
                    `font-medium disabled:!border-transparent disabled:!px-0`,
                    `${errors.Password ? "titext-error" : ""}`,
                  )}
                />
                {errors.Password && (
                  <MdOutlineErrorOutline
                    className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
                  />
                )}
              </div>

              {errors.Password && (
                <span className="text-sm font-semibold text-red-500">
                  {errors.Password.message}
                </span>
              )}
            </div>
            <div className=" mb-4 flex flex-col space-y-2 lg:!mb-0">
              <label className="invisible text-lg font-semibold">Button</label>
              <button
                type={"submit"}
                disabled={
                  !isEditing ||
                  (user.Mobile === values.Mobile && user.Name === values.Name && user.Password === values.Password)
                }
                className="default-btn ml-0 bg-emerald-500/85 hover:bg-emerald-500 disabled:cursor-not-allowed disabled:bg-green-600"
              >
                <IoMdCloudDone />
                <span>Save</span>
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="mb-8">
        <h1 className="mb-2 text-3xl font-semibold text-zinc-800">
          Account Settings
        </h1>
        <p className="mb-4">
          This sections needs to be handled with care since nothing here can be
          reverted back to how it was.
        </p>
        <div className="flex space-x-6">
          <button
            className="rounded-md bg-red-600 px-4 py-2 text-lg font-semibold tracking-wider text-white"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete My Account
          </button>
        </div>
      </div>
      <DeleteModal
        open={showDeleteModal}
        setOpen={setShowDeleteModal}
        session={session}
      />
    </div>
  );
};

function DeleteModal({ open, setOpen, session }) {
  const cancelButtonRef = useRef(null);

  const redirect = useNavigate();
  const deactivateAccount = () => {
    try {
      const res = fetch(`${ApiUrl}Users?userId=${session.id}&Otp=0`).then(
        () => {
          redirect("/delete-account");
        },
      );

      toast.promise(
        res,
        {
          loading: "Sending OTP!",
          success: (data) => `OTP successfully sent to your email!`,
          error: (err) => `Oops! Something went wrong while sending the OTP. Please try again!`,
        },
        {
          style: {
            minWidth: "250px",
          },
        },
      );
    } catch (error) {
      console.error("something went wrong...");
    } finally {
      setOpen(false);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto" >
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0" style={{alignItems:'center'}}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <FaExclamationTriangle
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Delete account
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Are you sure you want to delete your account? All of
                          your data will be permanently removed. This action
                          cannot be undone.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={deactivateAccount}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default Profile;
