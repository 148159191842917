import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Slider from "react-slick";
import { MdWorkspacePremium } from "react-icons/md";
import toast from "react-hot-toast";
import { getSession } from "../../lib/session";
import { VideoThumbUrl, VideoUrl } from "../../Constants/Config";
import { useNavigate } from "react-router-dom";
import VideoDisplayModal from "../Modals/VideoDisplayModal";
import {
  IdentifyVideoService,
  getVimeoVideoId,
  extractSrcFromHtmlInput,
  extractYoutubeVideoId,
  extractShortsVideoId,
} from "../../lib/helper";
function VideoSlide(props) {

  useEffect(() => {
    console.log("props.GroupData", props);
  }, []); 
  const session = getSession();
  const navigate = useNavigate();
  const settings = {
    centerMode: false,
    centerPadding: "5px",
    slidesToShow: 4,
    arrows: true,
    dots: false,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          arrows: true,
          centerMode: true,
          centerPadding: "10px",
          slidesToShow: 2,
          dots:false,
      
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          dots: false,
          centerMode: true,
          centerPadding: "10px",
          slidesToShow: 1,
          autoplaySpeed: 3000,          
        },
      },
    ],
  };
 
   

  //Video Modal
  const [showModal, setShowModal] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);
  const handleClose = () => setShowModal(false);
 
  const VideoClick = (data) => {


    const isPremiumUser = session.isPremiumUser;
    if (isPremiumUser || data.IsPremium !== 1) {
      let videoSrc;
      if (data.VideoType === 1) {
        const linkType = IdentifyVideoService(data.VideoUrl);
        if (linkType === "Vimeo") {
          videoSrc = `https://player.vimeo.com/video/${getVimeoVideoId(data.VideoUrl)}?autoplay=1&loop=1&autopause=0`;
        } else if (linkType === "YouTube") {
          videoSrc = `https://www.youtube.com/embed/${extractYoutubeVideoId(data.VideoUrl)}?autoplay=1`;
        } else if (linkType === "YouTubeShorts") {
          videoSrc = `https://www.youtube.com/embed/${extractShortsVideoId(data.VideoUrl)}?autoplay=1`;
        } else if (linkType === "iframe") {
          console.log('extractSrcFromHtmlInput(data.VideoUrl)',extractSrcFromHtmlInput(data.VideoUrl));
          videoSrc = extractSrcFromHtmlInput(data.VideoUrl);
        } else {
          toast.error("The provided video URL is not supported or invalid!");
        }
      } else {
        videoSrc = VideoUrl + data.VideoUrl;
      }
      setCurrentVideo(videoSrc);
      setShowModal(true);
    } else {
      toast.error("Please purchase a package");
    }
  };
  // console.log('props.VideoData',props.VideoData);
  return (
    <>
     { currentVideo&&<VideoDisplayModal
        showModal={showModal}
        currentVideo={currentVideo}
        handleClose={handleClose}
      />}
      {props.VideoData?.length > 0 && (
        <div className="trendingvideos">
          <div className="container">
            <div className="row" style={{ clear: "both", display: "flex" }}>
              <div className="col-lg-10 col-md-8 col-sm-12" onClick={() =>
                      navigate(`/Group/${props.GroupData.Id}/Type/2`)
                    }>
                <h2 className="Group-Heading">{props.GroupData.Heading}</h2>
              </div>
              {props.VideoData?.length > 4 && (
                <div className="col-lg-2 col-md-4 ">
                  <button
                    className="viewall-btn"
                    onClick={() =>
                      navigate(`/Group/${props.GroupData.Id}/Type/2`)
                    }
                  >
                    View All
                  </button>
                </div>
              )}
            </div>
            <Slider {...settings}>
              {props.VideoData?.map((data, index) => (
                <div className="video-item" key={index}>
                  <button
                    className={`trendvideo-item  ${data.IsPremium === 1 ? "premium-icon" : ""} ${data.IsCopyRight === 1 ? "copy-icon" : ""}`}
                    onClick={() =>{ VideoClick(data);console.log("kiran data",data);}
                    }
                   
                    style={{ position: "relative" }}
                  >
                    <span className="Videospan premium-icon-span">
                      {data.VideoType === 1 &&
                      !data.ThumbImg &&
                      IdentifyVideoService(data.VideoUrl) === "YouTube" ? (
                        <img
                          src={`https://img.youtube.com/vi/${extractYoutubeVideoId(data.VideoUrl)}/maxresdefault.jpg`}
                          alt="Prithvi"
                          style={{ width: "100%" }}
                        />
                      ) : (
                        <img
                          src={VideoThumbUrl + data.ThumbImg}
                          alt="uploaded video"
                          style={{ width: "100%" }}
                        />
                      )}
                    </span>
                    <div className="videocontent">
                      <font className="pulse-btn">
                        <i className="fas fa-play" />
                      </font>
                      <h6>{data.Heading}</h6>
                    </div>
                  </button>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
}

export default VideoSlide;
