import ApiClient from './ApiClient';
import toast from "react-hot-toast";

class UserHomeService {
	constructor(callback) {
		this.callback = callback;
	}

	async GetPrincipleGroup(payload) {
		const response = await ApiClient.get('PrinciplesGroup')
			.then((response) => {
				const filteredData = response.data?.filter(item => item.Status === 0);
				return filteredData;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetArticle(payload) {
		const response = await ApiClient.get('PrinciplesArticle')
			.then((response) => {
				const filteredData = response.data?.filter(item => item.Status === 0);
				return filteredData;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetSingleArticle(payload) {
		const response = await ApiClient.get(`PrinciplesArticle?Id=${payload.Id}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetFullForum(payload) {
		const response = await ApiClient.get('AllForum')
			.then((response) => {
				const filteredData = response.data?.filter(item => item.Status === 0);
				return filteredData;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetForumMessages(payload) {
		const response = await ApiClient.get(`ForumComment?forumId=${payload.forumId}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async InsertForumMessages(payload) {
		const response = await ApiClient.post('ForumComment', payload)
			.then((response) => {
				if (response?.data[0].Error === 0) {
					toast.success("Successfully added your comment!");
				} else {
					toast.error("Failed to add your comment. Please try again.");
				}
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async CreateForum(payload) {
		const response = await ApiClient.post('Forum', payload)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetWebinar(payload) {
		const response = await ApiClient.get(`Webinar?userId=${payload.userId}`)
			.then((response) => {
				const filteredData = response.data?.filter(item => item.Status === 0);
				return filteredData;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetAllPackages(payload) {
		const response = await ApiClient.get('PackageUser')
			.then((response) => {
				const filteredData = response.data?.filter(item => item.Status === 0);
				return filteredData;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async SearchDrugItem(payload) {
		const response = await ApiClient.get(`DrugItem?val=${payload.val}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetSingleForum(payload) {
		const response = await ApiClient.get(`Forum?forumId=${payload.forumId}`)
			.then((response) => {
				return response.data[0];
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetTwoForum(payload) {
		const response = await ApiClient.get('Forum')
			.then((response) => {
				const filteredData = response.data?.filter(item => item.Status === 0);
				return filteredData;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetDrugWithId(payload) {
		const response = await ApiClient.get(`DrugItem?DrugId=${payload.DrugId}&Dummy=${payload.Dummy}`)
			.then((response) => {
				return response.data[0];
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetAllDrugs(payload) {
		const response = await ApiClient.get('DrugItem')
			.then((response) => {
				const filteredData = response.data?.filter(item => item.Status === 0);
				return filteredData;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async PackagePurchase(payload) {
		console.log(payload,'payload');
		const response = await ApiClient.post('UserPackageMapping', payload)
			.then((response) => {
				if (response.data[0].Error === 0) {
					toast.success('Package purchased successfully!');
				} else {
					toast.error('Failed to purchase package. Please try again.');
				}
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetGroupByType(payload) {
		const response = await ApiClient.get(`PrinciplesArticle?type=${payload.type}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetAnnouncement(payload) {
		const response = await ApiClient.get('PrinciplesArticle?type=5')
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetBannerData() {
		const response = await ApiClient.get('banner')
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async PushNotification(payload) {
		const response = await ApiClient.post('PushNotification', payload)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GroupDataUsingGid(payload) {
		try {
			const response = await ApiClient.get(`PrinciplesArticle?groupId=${payload.groupId}`);
			const filteredData = response.data?.map(group => ({
				GroupName: group.GroupName,
				Data: group.Data?.filter(item => item?.Status === 0)
			}));
			return filteredData;
		} catch (error) {
			return { error: JSON.stringify(error) };
		}
	}

	async GetNotification(payload) {
		try {
			const response = await ApiClient.get(`Notification?UserId=${payload.UserId}`);
			return response.data;
		} catch (error) {
			return { error: JSON.stringify(error) };
		}
	}

	async ReadNotification(payload) {
		try {
			const response = await ApiClient.get(`Notification?Id=${payload.Id}`);
			return response.data;
		} catch (error) {
			return { error: JSON.stringify(error) };
		}
	}

	// async GetGroupDataByType(payload) {
	// 	const response = await ApiClient.get(`PrinciplesArticle?type=${payload.type}&dummy=${payload.dummy}`)
	// 		.then((response) => {
	// 			return response.data;
	// 		})
	// 		.catch((error) => ({ error: JSON.stringify(error) }));
	// 	return response;
	// }
	async GetArticles(payload) {
		const response = await ApiClient.get(`PrinciplesArticle?type=1&dummy=1`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}
	async GetVideos(payload) {
		const response = await ApiClient.get(`PrinciplesArticle?type=2&dummy=1`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetSingleShorts(payload) {
		const response = await ApiClient.get(`ShortVideo?UserId=${payload.UserId}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async UpdateShortsStatus(payload) {
		const response = await ApiClient.post(`ShortVideo?UserId=${payload.UserId}&ShortsId=${payload.ShortsId}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async PushNotificationUpdate(payload) {
		const response = await ApiClient.get(`forumcomment?UserId=${payload.UserId}&IsTurnOn=${payload.IsTurnOn}&Type=${payload.Type}`)
			.then((response) => {
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}

	async GetNotificationStatus(payload) {
		const response = await ApiClient.get(`PushNotification?UId=${payload.UId}`)
			.then((response) => {
				console.log(response.data,'push');
				return response.data;
			})
			.catch((error) => ({ error: JSON.stringify(error) }));
		return response;
	}
}

export default new UserHomeService();
