import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Packages from '../Pages/Packages/Packages';
import Login from '../Pages/Login/Login';
import Register from '../Pages/Register/Register';
import ForgotPassword from '../Pages/ForgotPassword/ForgotPassword';
import AskGuruLayout from '../Pages/Askguru/AskLayout';
import SingleForum from '../Pages/Forums/SingleForum';
import ForumPage from '../Pages/Forums/ForumPage';
import DrugListTest from '../Components/Drug/DrugList';
import SingleDrug from '../Components/Drug/SingleDrug';
import Ebooks from '../Components/UserHome/Ebooks';
import SingleBookPdf from '../Pages/Ebook/SingleBookPdf';
import WhatsAppStories from '../Pages/shorts/Shorts';
import ShortsDemo from '../Pages/shorts/ShortsDemo';
import Terms from '../Pages/legal/Terms';
import Privacy from '../Pages/legal/Privacy';
import Profile from '../Pages/profile/page';
import DeleteProfile from '../Pages/profile/deleteProfile';
import SingleArticle from '../Pages/Articles/SingleArticle';
import HomePage from '../Pages/UserHome/HomePage';
import AllType from '../Pages/AllGroup/AllType';
import AllGroup from '../Pages/AllGroup/AllGroup';
import SampleChat from '../Pages/chat/SampleChat';
import WebinarMobile from '../Pages/UserHome/WebinarMobile';
import { getSession } from '../lib/session';
import Notification from '../Pages/Notification/Notification';
import AllTypeCategorized from '../Pages/AllGroup/AllTypeCategorized';
import ShortsModal from '../Pages/shorts/ShortsModal';
import Search from '../Pages/search/Search';
import Contactus from '../Pages/ContactUs/Contactus';
import Contact from '../Pages/legal/Contact';
import About from '../Pages/legal/About';
import Shipping from '../Pages/legal/Shipping'
import Cancelpolicy from '../Pages/legal/Cancelpolicy';
import AllArticles from '../Pages/AllGroup/AllArticles';
import AllVideos from '../Pages/AllGroup/AllVideos';
import AllAudios from '../Pages/AllGroup/AllAudios';
import AllPoster from '../Pages/AllGroup/AllPoster';
import AllPuzzle from '../Pages/AllGroup/AllPuzzle';
import AllEbooks from '../Pages/AllGroup/AllEbooks';

const ProtectedRoute = ({
    session,
    component: Component,
    navigateTo,
    ...rest
}) => {
    return session && session.id ? (
        <Component />
    ) : (
        <Navigate to={navigateTo} replace={true} />
    );
};

function Router() {
    const session = getSession();
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/reset-password" element={<ForgotPassword />} />
            <Route
                path="/user-home"
                element={
                    <ProtectedRoute
                        session={session}
                        component={HomePage}
                        navigateTo="/"
                    />
                }
            />
            <Route
                path="/Packages"
                element={
                    <ProtectedRoute
                        session={session}
                        component={Packages}
                        navigateTo="/"
                    />
                }
            />

            <Route
                path="/chat"
                element={
                    <ProtectedRoute
                        session={session}
                        component={SampleChat}
                        navigateTo="/"
                    />
                }
            />
            <Route
                path="/drug"
                element={
                    <ProtectedRoute
                        session={session}
                        component={DrugListTest}
                        navigateTo="/"
                    />
                }
            />
            <Route
                path="/shorts"
                element={
                    <ProtectedRoute
                        session={session}
                        component={ShortsDemo}
                        navigateTo="/"
                    />
                }
            />
            <Route
                path="/drug/:Id"
                element={
                    <ProtectedRoute
                        session={session}
                        component={SingleDrug}
                        navigateTo="/"
                    />
                }
            />
            <Route
                path="/forum/:Id"
                element={
                    <ProtectedRoute
                        session={session}
                        component={SingleForum}
                        navigateTo="/"
                    />
                }
            />
            <Route
                path="/forum"
                element={
                    <ProtectedRoute
                        session={session}
                        component={ForumPage}
                        navigateTo="/"
                    />
                }
            />
            {/* <Route
                path="/Type/:Id"
                element={
                    <ProtectedRoute
                        session={session}
                        component={AllTypeCategorized}
                        navigateTo="/"
                    />
                }
            /> */}
            <Route
                path="/Type/1"
                element={
                    <ProtectedRoute
                        session={session}
                        component={AllArticles}
                        navigateTo="/"
                    />
                }
            />
            <Route
                path="/Type/2"
                element={
                    <ProtectedRoute
                        session={session}
                        component={AllVideos}
                        navigateTo="/"
                    />
                }
            />
            <Route
                path="/Type/3"
                element={
                    <ProtectedRoute
                        session={session}
                        component={AllPuzzle}
                        navigateTo="/"
                    />
                }
            />
             <Route
                path="/Type/4"
                element={
                    <ProtectedRoute
                        session={session}
                        component={AllPoster}
                        navigateTo="/"
                    />
                }
            />
             <Route
                path="/Type/6"
                element={
                    <ProtectedRoute
                        session={session}
                        component={AllEbooks}
                        navigateTo="/"
                    />
                }
            />
              
          
           
          
            <Route
                path="/Type/8"
                element={
                    <ProtectedRoute
                        session={session}
                        component={AllAudios}
                        navigateTo="/"
                    />
                }
            />
          
           
            <Route
                path="/Ebook/:Id"
                element={
                    <ProtectedRoute
                        session={session}
                        component={SingleBookPdf}
                        navigateTo="/"
                    />
                }
            />
           
            <Route path="/Contact-Us" element={<Contactus />} />
            <Route path="/legal/terms" element={<Terms />} />
            <Route path="/legal/privacy" element={<Privacy />} />
            <Route path="/legal/contact" element={<Contact />} />
            <Route path="/legal/about" element={<About />} />
            <Route path="/legal/cancel" element={<Cancelpolicy />} />
            <Route path="/legal/shipping" element={<Shipping />} />

            <Route
                path="/profile"
                element={
                    <ProtectedRoute
                        session={session}
                        component={Profile}
                        navigateTo="/"
                    />
                }
            />
            <Route
                path="/delete-account"
                element={
                    <ProtectedRoute
                        session={session}
                        component={DeleteProfile}
                        navigateTo="/"
                    />
                }
            />
            <Route path="/Article/:Id" element={<SingleArticle />} />
            <Route
                path="/Webinar"
                element={
                    <ProtectedRoute
                        session={session}
                        component={WebinarMobile}
                        navigateTo="/"
                    />
                }
            />
            <Route
                path="/Notification"
                element={
                    <ProtectedRoute
                        session={session}
                        component={Notification}
                        navigateTo="/"
                    />
                }
            />
            <Route
                path="/Group/:GroupId/Type/:TypeId"
                element={
                    <ProtectedRoute
                        session={session}
                        component={AllGroup}
                        navigateTo="/"
                    />
                }
            />

            <Route
                path="/search"
                element={
                    <ProtectedRoute
                        session={session}
                        component={Search}
                        navigateTo="/"
                    />
                }
            />
        </Routes>
    );
}

export default Router;
