import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { WebinarImgUrl } from "../../Constants/Config";
import { FormattedDate2 } from "../../Constants/FormattedDate";
import GuruSvg from "../../img/guru-image.svg";
import { GetWebinar } from "../../Redux/UserHome/action";
import WebinarDisplayModal from "../Modals/WebinarDisplayModal";
import { useNavigate } from "react-router-dom";
import { getSession } from "../../lib/session";
import toast from "react-hot-toast";

function AskGuru() {
  const dispatch = useDispatch();
  const session=getSession()
  const WebinarData = useSelector((state) => state.UserHomeReducer.GetWebinar);
  

  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(
      GetWebinar({
        userId: 41,
      }),
    );
  }, []);

  const sliderSettings = {
    // dots: false,
    arrows: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnFocus:true,
    pauseOnHover:true,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: true,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  //Article show Modal
  const [ShowWebinarModal, setShowWebinarModal] = useState(false);
  const CloseWebinarModal = () => {
    setShowWebinarModal(false);
  };

  const [SelectedWebinarData, setSelectedWebinarData] = useState([]);
  const WebinarClick = (Data) => {
    const isPremiumUser = session.isPremiumUser;
    if (isPremiumUser || Data.IsPremium !== 1) {
      setSelectedWebinarData(Data);
      setShowWebinarModal(true);
    } else {
      toast.error("Please purchase a package");
    }
  };
  
  return (
    <>
      <WebinarDisplayModal
        ShowWebinarModal={ShowWebinarModal}
        CloseWebinarModal={CloseWebinarModal}
        SelectedWebinarData={SelectedWebinarData}
      />

      <div className="askguru-wrap">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="guru-icon">
                <img src={GuruSvg} alt="guru" />
              </div>
              <h2>Ask Guru</h2>
              <p>
              Connect with senior vaidyas for personalized guidance. Chat directly, ask your questions, and choose experts from your region for localized advice.
              </p>
              <div className="ask-text-input">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ask your question"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  style={{ marginBottom: "30px" }}
                />
                <button
                  onClick={() =>
                    navigate(`/chat?message=${message}&newChat=${true}`)
                  }
                >
                  <i className="fas fa-arrow-right" />
                </button>
              </div>
            </div>
            {WebinarData?.length > 0 && (
              <div className="col-lg-4 col-md-12">
                <div className="webinar-wrapper">
                  <h2>Webinar</h2>
                  <Slider {...sliderSettings} className="webinar">
                    {WebinarData?.map((data) => (
                      <div
                        className={`carousel-item ${data.IsPremium === 1 ? "premium-icon" : ""} ${data.IsCopyRight === 1 ? "copy-icon" : ""}`}
                        key={data.Id}
                        onClick={() => WebinarClick(data)}
                      >
                        <span className='premium-icon-span'>
                        <img src={WebinarImgUrl + data.Image} alt="webinar" />
                        </span>
                        
                        <h5>Starts On : {FormattedDate2(data.StartDate)}</h5>
                        <p>{data.Name}</p>
                        <button
                          style={{
                            marginTop: "4px",
                            background: "#ad7b42",
                            border: "1px solid ghostwhite",
                            color: "white",
                            padding: "5px 10px",
                            fontSize: "12px",
                            borderRadius: "5px",
                          }}
                        >
                          Know more
                        </button>
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AskGuru;
