import React from 'react';

const OfflineIndicator = () => {
    return (
        <div style={{ 
            textAlign: 'center', 
            alignItems: "center", 
            justifyContent: "center", 
            display: 'flex', 
            padding: '20px', 
            color: '#721c24', 
            height: '100vh', // Full screen height
            width: '100vw',  // Full screen width
            marginTop: '0',  // Remove margin to center it better
            flexDirection: 'column', 
            backgroundColor: '#ad7b42'  // Background color around the image
        }}>
            <img src="/net.jpg" style={{ maxWidth: '100%', maxHeight: '100%' }} alt="Offline" />
        </div>
        
        
    );
};

export default OfflineIndicator;