import React from 'react';
import privacySvg from '../../img/privacy_policy.svg';
import { useNavigate } from 'react-router-dom';
import CombinedFooter from '../../Components/Footer/CombinedFooter';
import useHardwareBackButton from '../../Constants/HardwareBackButtonPress';

const Shipping = () => {
    const redirect = useNavigate();
    const handleBackButtonPress = () => {
        redirect('/user-home');
    };
    useHardwareBackButton(0, handleBackButtonPress);
    return (
        <div className="flex h-screen flex-col">
            <div className="relative w-full overflow-x-scroll pt-[40px]">
                <div className="bg-[hsla(0, 0%, 100%, 0.9)] fixed top-0 z-[999] flex h-fit w-full justify-between bg-white text-center shadow-md">
                    <button
                        className={
                            'flex h-auto w-[42px] items-center justify-center bg-darkBrown text-white'
                        }
                        onClick={() => redirect('/user-home')}
                    >
                        <i className="fas fa-chevron-left"></i>
                    </button>
                    <div
                        className={
                            'flex h-[42px]  items-center justify-center '
                        }
                    ></div>
                    <button
                        className={
                            'flex h-auto w-[42px] items-center justify-center opacity-0'
                        }
                    ></button>
                </div>
            </div>
            <div className="container relative mx-auto flex h-screen flex-1  overflow-hidden px-4 pb-14 md:pb-0">
                <div className="w-full overflow-y-auto overscroll-none scrollbar-hide">
                    {/* <img
            src={privacySvg}
            alt={"terms and conditions"}
            className="mx-auto h-auto w-full max-w-[600px]"
          /> */}
                    <div className="mb-8 mt-8">
                        <h1
                            id={'Interpretation'}
                            className="mb-3 text-4xl font-semibold tracking-tight"
                        >
                            Shipping and Delivery Policy
                        </h1>
                        <p className="leading-7 tracking-wide">
                            The Prithvi app/website provides digital content and
                            services, with no physical items for shipment. All
                            purchases are delivered instantly through the app or
                            website upon completion of payment.
                        </p>
                        <p className="leading-7 tracking-wide">
                            For assistance, please reach out to our support team
                            at psathulyam@gmail.com
                        </p>
                    </div>
                </div>
            </div>
            <div className="sticky bottom-0">
                <CombinedFooter />
            </div>
        </div>
    );
};

export default Shipping;
