import React, { useState, useRef } from 'react';
import Slider from 'react-slick';

import { getSession } from '../../lib/session';
import { VideoThumbUrl } from '../../Constants/Config';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import AudioPlayModal from '../Modals/AudioPlayModal';
import { useEffect } from 'react';
import AudioPlayer from '../Modals/AudioPlayModal';
import { audioState } from '../../audioState';
import { data } from 'jquery';

function AudioSlide(props) {
    const session = getSession();
    const navigate = useNavigate();

    const audioData = props.AudioData || [];

    const [showModal, setShowModal] = useState(false);
    const [showAudio, setShowAudio] = useState(false);
    const [showVideo, setShowVideo] = useState(false);
    const [modalData, setModalData] = useState(null);
    const audioRef = useRef(null);

    const toggleAudioState = (Data) => {
        if (audioState) {
            console.log('there', audioState.videoUrl);
        }
      
        
        console.log('dataaa = > toggled ', Data);
        const isPremiumUser = session.isPremiumUser;
        const canAccessAudio = isPremiumUser || Data.IsPremium !== 1;
        if (!canAccessAudio) {
            toast.error('Please purchase a package');
            return;
        }
        // If access is granted, set the modal data and open the modal
        setModalData(Data);
        // setShowModal(true);
        if(Data.VideoUrl){
            setShowVideo(true);
            setShowAudio(false);
        }else{
            setShowVideo(false);
            setShowAudio(true);
        }
        console.log(showAudio,"showAudio");
        console.log(showVideo,"showVdo");
    };
    

    const handleCloseModal = () =>
        // setShowModal(false);
        {
                
                setShowVideo(false);
                setShowAudio(false);
            
        };
    const settings = {
        centerMode: false,
        centerPadding: '5px',
        slidesToShow: 4,
        arrows: true,
        // dots: true,
        infinite: false,
        autoplay: false,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    arrows: true,
                    centerMode: true,
                    centerPadding: '0px',
                    slidesToShow: 1
                }
            }
        ]
    };

    return (
        <>
        {
            showAudio && ( <AudioPlayModal
                showModal={showAudio}
                handleCloseModal={handleCloseModal}
                AudioData={modalData}
                onLoadedMetadata={(e) => {
                    e.target.currentTime = audioData?.currentTime || 0; // Set audio position from state
                }}
            />)
        }
         {
            showVideo && ( <AudioPlayModal
                showModal={showVideo}
                handleCloseModal={handleCloseModal}
                AudioData={modalData}
                onLoadedMetadata={(e) => {
                    e.target.currentTime = audioData?.currentTime || 0; // Set audio position from state
                }}
            />)
        }
           

            {audioData.length > 0 && (
                <div className="trendingvideos">
                    <div className="container">
                        <div
                            className="row"
                            style={{ clear: 'both', display: 'flex' }}
                        >
                            <div
                                className="col-lg-10 col-md-8 col-sm-12"
                                onClick={() =>
                                    navigate(
                                        `/Group/${props.GroupData.Id}/Type/8`
                                    )
                                }
                            >
                                <h2 className="Group-Heading">
                                    {props.GroupData.Heading}
                                    {showAudio}
                                    {showVideo}
                                </h2>
                            </div>
                            {audioData.length > 4 && (
                                <div className="col-lg-2 col-md-4">
                                    <button
                                        className="viewall-btn"
                                        onClick={() =>
                                            navigate(
                                                `/Group/${props.GroupData.Id}/Type/2`
                                            )
                                        }
                                    >
                                        View All
                                    </button>
                                </div>
                            )}
                        </div>
                        <Slider {...settings}>
                            {audioData.map((data, index) => (
                                <div className="video-item" key={index}>
                                    <button
                                        className={`trendvideo-item  ${data.IsPremium === 1 ? 'premium-icon' : ''} ${data.IsCopyRight === 1 ? 'copy-icon' : ''}`}
                                        style={{ position: 'relative' }}
                                        onClick={() => toggleAudioState(data)}
                                    >
                                        <span className="Videospan premium-icon-span">
                                            <img
                                                src={
                                                    VideoThumbUrl +
                                                    data.ThumbImg
                                                }
                                                alt="uploaded video"
                                                style={{ width: '100%' }}
                                            />
                                        </span>
                                        <div className="videocontent">
                                            <font className="pulse-btn">
                                                <i className={'fas fa-play'} />
                                            </font>
                                            <h6>{data.Heading}</h6>
                                        </div>
                                    </button>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            )}
        </>
    );
}

export default AudioSlide;
