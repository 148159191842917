    import React, { useEffect, useState } from "react";
    import { useDispatch, useSelector } from "react-redux";
    import { useNavigate } from "react-router-dom";
    import CombinedFooter from "../../Components/Footer/CombinedFooter";
    import { getSession } from "../../lib/session";
    import { GetNotification, ReadNotification } from "../../Redux/UserHome/action";
    import { getTimeAgoString } from "../../Constants/FormattedDate";
    import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";

    function Notification() {
      const redirect = useNavigate();
      const handleBackButtonPress = () => {
        redirect("/user-home");
      };
      useHardwareBackButton(0, handleBackButtonPress);
      const dispatch = useDispatch();
      const session = getSession();
      const NotificationData = useSelector(
        (state) => state.UserHomeReducer.NotificationData,
      );
      const IsReadResponse = useSelector(
        (state) => state.UserHomeReducer.ReadNotification,
      );
      const [expandedMessageIndex, setExpandedMessageIndex] = useState(null);

      useEffect(() => {
        dispatch(
          GetNotification({
            UserId: session.id,
          }),
        );
      }, [IsReadResponse]);

      const handleViewMore = (index) => {
        setExpandedMessageIndex(index);
      };

      const handleViewLess = () => {
        setExpandedMessageIndex(null);
      };
      const HandleReadNotification = (Id) => {
        dispatch(
          ReadNotification({
            Id: Id,
          }),
        );
      };
      return (
        <div className="flex min-h-screen flex-col">
          <div className="bg-[hsla(0, 0%, 100%, 0.9)] fixed top-0 z-[999] flex h-[42px] w-full justify-start bg-white text-center shadow-md">
            <button
              className="flex h-auto w-[42px] items-center justify-center bg-darkBrown text-white"
              onClick={() => redirect("/user-home")}
            >
              <i className="fas fa-chevron-left"></i>
            </button>
          </div>
          <div className="container mx-auto flex-grow py-8 pb-20 pt-16">
            <div className="mb-6 text-center">
              <h1 className="text-2xl font-semibold">Notifications</h1>
            </div> 
            {NotificationData && NotificationData?.length > 0 ? (
              <div>
                {NotificationData?.map((notification, index) => (
                  <div
                    key={index}
                    className="group relative mb-6 flex origin-bottom flex-col rounded-lg  border border-transparent bg-white p-6 shadow-md transition-all hover:scale-[101%] hover:!border-zinc-600"
                  >
                    <button
                      className="close-button absolute right-2 top-2 cursor-pointer border-none bg-transparent focus:outline-none"
                      onClick={() => HandleReadNotification(notification.id)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6 text-gray-800 hover:text-gray-900"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M14.293 5.293a1 1 0 011.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 111.414-1.414L10 8.586l4.293-4.293a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>

                    <p
                      className="mb-3 flex-1 text-base font-medium text-zinc-700"
                      style={{
                        maxHeight: expandedMessageIndex === index ? "none" : "3rem",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {notification.Message}
                    </p>
                    {notification.Message?.length > 300 && (
                      <div>
                        {expandedMessageIndex !== index ? (
                          <button
                            className="text-blue-500"
                            onClick={() => handleViewMore(index)}
                          >
                            Show more
                          </button>
                        ) : (
                          <button
                            className="text-blue-500"
                            onClick={() => handleViewLess()}
                          >
                            Show less
                          </button>
                        )}
                      </div>
                    )}
                    <div className="mt-3 flex justify-between text-zinc-400">
                      <p className="text-sm text-zinc-600/90 transition-all">
                        {notification.Name}
                      </p>
                      <p className="text-sm text-zinc-600/90 transition-all">
                        {getTimeAgoString(notification.SysTime)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex h-48 items-center justify-center">
                <p className="text-lg text-gray-500">No notifications available.</p>
              </div>
            )}
          </div>
          <CombinedFooter className="absolute bottom-0 w-full" />
        </div>
      );
    }

    export default Notification;
