import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import './updatecss.css';
import PrithviLogo from "../../img/the-prithvi-logo.png";

function UpdateNotifictationModal(props) {
  const [PlaystoreLink, setPlaystoreLink] = useState('https://play.google.com/store/apps/details?id=com.init.prithviapp');

//   useEffect(() => {
//     axios
//       .get(BaseUrl + /getinitsettings)
//       .then((response) => {
//         // console.log(response.data[0].PlaystoreUrl);
//         setPlaystoreLink(response.data[0].PlaystoreUrl);
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   }, [props.Show]);

  const handleUpdateClick = () => {
    window.location.href = PlaystoreLink;
  };

  return (
    <Modal show={props.Show} size="sm" onHide={props.close} centered>
      <Modal.Body className="alert-boxwrap updatecancel-btn">
      <img
              src={PrithviLogo}
              alt="Prithvi Logo"
              style={{
                width: "auto", // Adjust width as needed
                height: "100%", // Maintain aspect ratio
              }}
            />
        <h5>New Update Available!  </h5>
        <p>We’re excited to bring you the latest version of The Prithvi App with improved features and performance. Update now for the best experience!</p>
        <div className="row">
          <div className="col-12">
            <button
              className="updatebtn"
              style={{ width: '100%', background: '#b78751', color: '#fff', marginBottom: '15px' }}
              onClick={handleUpdateClick}
            >
              Update Now
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default UpdateNotifictationModal;