import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EbookPdfUrl } from '../../Constants/Config';

const SingleBookPdf = () => {
  const { Id } = useParams();
  const navigate = useNavigate();
  const [PdfSrc, setPdfSrc] = useState(null);

  useEffect(() => {
    if (Id?.length > 0) {
      const Pdf = EbookPdfUrl + Id;
      setPdfSrc(Pdf);
    }
    return () => {
      setPdfSrc(null);
    };
  }, [Id]);

  return (
    <div>
      <div className="Article-Modal home-slider">
        <div className="page-header-con">
          <button className="backbtn" onClick={() => navigate(-1)}>
            <i className="fas fa-chevron-left"></i>
          </button>
        </div>
      </div>
      {PdfSrc && (
        <div>
          {/* Wrap the iframe in a container for better styling and control */}
          <div style={{ overflow: 'hidden', height: '650px' }}>
            <iframe
              title="PDF Viewer"
              src={`https://docs.google.com/gview?url=${PdfSrc}&embedded=true`}
              width="100%"
              height="100%"
              style={{ border: "none" }}
              sandbox="allow-same-origin allow-scripts"
            ></iframe>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleBookPdf;
