import React, { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { twMerge as tw } from 'tailwind-merge';
import { loginSchema } from '../../lib/schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { MdOutlineErrorOutline } from 'react-icons/md';
import { json, Link, useNavigate, useSearchParams } from 'react-router-dom';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { GetUser, GetUserError } from '../../Redux/Authentication/action';
import { mutateSession, setSession } from '../../lib/session';
import { ApiUrl, sessionKey } from '../../Constants/Config';
import toast from 'react-hot-toast';
import { ImSpinner2 } from 'react-icons/im';
import { PushNotification } from '../../Redux/UserHome/action';
import axios from 'axios';
import PrithviLogo from '../../img/the-prithvi-logo.png';
import { Capacitor } from '@capacitor/core';
import { PushNotifications } from '@capacitor/push-notifications';
import { genarateToken } from '../../firebase';
import { BsDot } from 'react-icons/bs';
const Eye = ({ control, update, visible }) => {
    const password = useWatch({
        control,
        name: 'password'
    });

    const username = useWatch({
        control,
        name: 'username'
    });

    return visible ? (
        <IoMdEye
            className={`absolute inset-y-0 right-0 z-50 mr-3 h-full cursor-pointer transition-opacity duration-200 ${password ? 'opacity-100' : 'opacity-0'}`}
            onClick={(e) => {
                update((ref) => !ref);
            }}
        />
    ) : (
        <IoMdEyeOff
            className={`absolute inset-y-0 right-0 z-50 mr-3 h-full cursor-pointer transition-opacity duration-200 ${password ? 'opacity-100' : 'opacity-0'}`}
            onClick={(e) => {
                update((ref) => !ref);
            }}
        />
    );
};

const LoginForm = () => {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const [params, setParams] = useSearchParams();

    const {
        register,
        formState: { errors, isValidating, isValid, dirtyFields },
        handleSubmit,
        getValues,
        setValue,
        control
    } = useForm({
        resolver: zodResolver(loginSchema),
        defaultValues: {
            username: params.get('email') || '',
            password: ''
        }
    });

    const username = useWatch({
        control,
        name: 'username'
    });

    const dispatch = useDispatch();
    const response = useSelector(
        (state) => state.AuthenticationReducer.GetUserState
    );
    const spinner = useSelector((state) => state.Common.spinner);
    const fetchData = async (Id) => {
        if (Capacitor.getPlatform() === 'android') {
            await PushNotifications.checkPermissions().then(async (res) => {
                if (res.receive !== 'granted') {
                    await PushNotifications.requestPermissions().then((res) => {
                        if (res.receive === 'denied') {
                            toast.error('Push Notification permission denied');
                        }
                    });
                }
                initializePushNotifications(Id);
            });
        } else if (Capacitor.getPlatform() === 'web') {
            const token = await genarateToken();
            if (Id && token) {
                dispatch(
                    PushNotification({
                        UserId: Id,
                        Token: token
                    })
                );
            }
        }
    };

    const initializePushNotifications = (Id) => {
        PushNotifications.register();

        // On success, we should be able to receive notifications
        PushNotifications.addListener('registration', (token) => {
            if (Id && token.value) {
                dispatch(
                    PushNotification({
                        UserId: Id,
                        Token: token.value
                    })
                );
            }
        });

        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError', (error) => {
            alert('Error on registration: ' + JSON.stringify(error));
        });

        PushNotifications.addListener(
            'pushNotificationReceived',
            (notification) => {
                console.log(
                    'pushNotificationReceived: ' + JSON.stringify(notification)
                );
            }
        );

        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            (notification) => {
                console.log('notification: ' + JSON.stringify(notification));

                const Data = notification.notification.data.Data;
                console.log('Data: ' + JSON.stringify(Data));

                if (Data) {
                    let parsedData;
                    try {
                        parsedData = JSON.parse(Data); // First parse the Data object
                        console.log(
                            'Parsed Data: ' + JSON.stringify(parsedData)
                        );
                    } catch (e) {
                        console.log('Error parsing Data: ' + e.message);
                        return;
                    }

                    const Url = parsedData.Url;
                    console.log('Url: ' + Url);

                    if (Url) {
                        navigate(Url);
                    } else {
                        console.log('Url is not defined in the parsed Data');
                    }
                } else {
                    console.log('Data is not available in the notification');
                }
            }
        );
    };

    useEffect(() => {
        if (response && response?.length > 0 && response[0].UserType === 2) {
            const role =
                response[0]?.UserType === 2
                    ? 'user'
                    : response[0]?.UserType === 1
                      ? 'admin'
                      : null;

            fetchData(response[0]?.Id);
            setSession(sessionKey, {
                name: response[0]?.Name,
                id: response[0]?.Id,
                mobile: response[0]?.Mobile,
                email: response[0]?.Email,
                role,
                isGuru: !!response[0]?.guruId,
                guruId: response[0]?.guruId ?? null,
                chats: response[0]?.noofChats,
                isPremiumUser: !response[0]?.IsPremium,
                planExpiresAt: response[0]?.EndedOn,
                Islogin: response[0]?.Islogin ?? null
            });

            const LoginData = {
                UserId: response[0]?.Id,
                UserName: response[0]?.Email
            };

            axios
                .post(ApiUrl + `Logins`, LoginData)
                .then((response) => {
                    if (response?.data[0]?.SecretKey) {
                        mutateSession({
                            SecretKey: response.data[0]?.SecretKey
                        });

                        navigate(`user-home`);
                    }
                })
                .catch((error) => {
                    // Handle any errors that occurred during the request
                    console.error('Error fetching data:', error);
                });
        } else if (response) {
            toast.error('Invalid Credentials!!!');
            setValue('password', '');
            dispatch(GetUserError());
        }
    }, [response]);

    const onSubmit = (data) => {
        dispatch(GetUser({ Username: data.username, Password: data.password }));
    };

    return (
        <div className="mt-[20%] flex w-full max-w-lg flex-1 flex-col items-center justify-center px-4 space-y-6" >
            <div className='mx-auto'>
                <img src={PrithviLogo} className="mt-[50%] w-[88px] md:hidden  " />
            </div>

            <div className="mx-auto flex w-full max-w-lg flex-1 flex-col items-center   ">
            <div className='flex items-center justify-center text-center p-4'>
    <h1 className="text-3xl font-bold">Welcome to Prithvi,<br />Please Login</h1>
</div>

                <form
                    className="mt-5 w-full items-center space-y-5"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="username">Username</label>
                        <div>
                            <div className="relative ">
                                <input
                                    type="text"
                                    id="username"
                                    className={tw(
                                        `block w-full !rounded-md !border-2 !border-solid !border-zinc-300 py-2 pl-3 pr-9 !outline-none transition-all ease-in-out focus:!border-zinc-600 focus:!outline-none focus:!ring-0 `,
                                        errors.username
                                            ? '!border-2 !border-red-500 focus:!border-red-500'
                                            : ''
                                    )}
                                    placeholder="Email / Phone No"
                                    {...register('username')}
                                />
                                {errors.username && (
                                    <MdOutlineErrorOutline
                                        className={`absolute inset-y-0 right-0 mr-3 h-full fill-red-500`}
                                    />
                                )}
                            </div>
                            {errors.username && (
                                <span className="text-sm font-medium text-red-500">
                                    {errors.username.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <label htmlFor="password">Password</label>
                        <div>
                            <div className="relative h-full">
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    className={tw(
                                        `peer block w-full !rounded-md !border-2 !border-solid !border-zinc-300 py-2 pl-3 pr-9 !outline-none transition-all ease-in-out focus:!border-zinc-600 focus:!outline-none focus:!ring-0 `,
                                        errors.password
                                            ? '!border-2 !border-red-500 focus:!border-red-500'
                                            : ''
                                    )}
                                    placeholder="************"
                                    {...register('password')}
                                />
                                <Eye
                                    control={control}
                                    update={setShowPassword}
                                    visible={showPassword}
                                />
                            </div>
                            {errors.password && (
                                <span className="text-sm font-medium text-red-500">
                                    {errors.password.message}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className={`mx-auto w-fit text-sm`}>
                        <Link
                            to={
                                username
                                    ? `reset-password?email=${username}`
                                    : 'reset-password'
                            }
                            className="animate font-semibold capitalize transition-all hover:cursor-pointer hover:underline"
                        >
                            forgot password?
                        </Link>
                    </div>
                    <div className="flex flex-col space-y-2">
                        <button
                            type="submit"
                            disabled={spinner}
                            className="mx-auto flex w-fit items-center space-x-2 rounded-md border bg-brown p-2 px-4 font-medium capitalize tracking-wide text-white transition-all hover:bg-darkBrown"
                        >
                            <span>{spinner ? 'Authenticating' : 'Login'}</span>
                            <ImSpinner2
                                className={`${spinner ? 'block animate-spin' : 'hidden'}`}
                            />
                        </button>
                    </div>
                </form>
            </div>
            <div className="my-4 flex w-full flex-col items-center space-y-2">
                <div className="flex w-full items-center justify-around">
                    <Link
                        to={'legal/privacy'}
                        className="animate block w-full text-center text-xs font-semibold capitalize transition-all hover:cursor-pointer hover:underline"
                    >
                        Privacy Policy
                    </Link>
                    <div className="flex items-center justify-center">
                        <BsDot />
                    </div>
                    <Link
                        to={'legal/terms'}
                        className="animate block w-full text-center text-xs font-semibold capitalize transition-all hover:cursor-pointer hover:underline"
                    >
                        Terms and Conditions
                    </Link>
                </div>

                <div className="flex w-full items-center justify-around">
                    <Link
                        to={'legal/cancel'}
                        className="animate block w-full text-center text-xs font-semibold capitalize transition-all hover:cursor-pointer hover:underline"
                    >
                        Cancellation and Refund Policy
                    </Link>
                    <div className="flex items-center justify-center">
                        <BsDot />
                    </div>
                    <Link
                        to={'legal/shipping'}
                        className="animate block w-full text-center text-xs font-semibold capitalize transition-all hover:cursor-pointer hover:underline"
                    >
                        Shipping and Delivery Policy
                    </Link>
                </div>

                <div className="flex w-full items-center justify-around">
                    <Link
                        to={'legal/about'}
                        className="animate block w-full text-center text-xs font-semibold capitalize transition-all hover:cursor-pointer hover:underline"
                    >
                        About Us
                    </Link>
                    <div className="flex items-center justify-center">
                        <BsDot />
                    </div>
                    <Link
                        to={'legal/contact'}
                        className="animate block w-full text-center text-xs font-semibold capitalize transition-all hover:cursor-pointer hover:underline"
                    >
                        Contact Us
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default LoginForm;
