import React, { useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { StatusBar } from '@capacitor/status-bar';

function VideoDisplayModal({ showModal, handleClose, currentVideo }) {
    const iframeRef = useRef(null);
    
    useEffect(() => {
        // const handleFullscreenChange = async () => {
        //     if (document.fullscreenElement) {
        //         await StatusBar.hide(); // Hide the status bar when entering fullscreen
        //     } else {
        //         await StatusBar.show(); // Show the status bar when exiting fullscreen
        //         handleClose(); // Close modal when exiting fullscreen
        //     }
        // };
        StatusBar.hide();

        // document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {

            StatusBar.show();

            // document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    const enterFullscreen = async () => {
        const iframe = iframeRef.current;

        if (iframe) {
            if (iframe.requestFullscreen) {
                await iframe.requestFullscreen();
            } else if (iframe.mozRequestFullScreen) {
                await iframe.mozRequestFullScreen();
            } else if (iframe.webkitRequestFullscreen) {
                await iframe.webkitRequestFullscreen();
            } else if (iframe.msRequestFullscreen) {
                await iframe.msRequestFullscreen();
            }
        }
    };

    const handlePlay = () => {
        const iframe = iframeRef.current;
        if (iframe) {
            iframe.contentWindow.postMessage(
                '{"event":"command","func":"playVideo","args":""}',
                '*'
            );
        }
    };

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            iframe.addEventListener('load', handlePlay);
        }

        return () => {
            if (iframe) {
                iframe.removeEventListener('load', handlePlay);
            }
        };
    }, []);

    return (
        <Modal show={showModal} centered>
            <Modal.Body style={{ padding: 0 }}>
                <div
                    style={{
                        position: 'relative',
                        paddingBottom: '56.25%', // 16:9 aspect ratio
                        height: 0
                    }}
                >
                    <iframe
                        ref={iframeRef}
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%'
                        }}
                        src={currentVideo}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen
                        onClick={enterFullscreen} // Click to enter fullscreen
                    ></iframe>
                    <button
                        style={{
                            backgroundColor: 'rgb(239 239 239)',
                            border: 'none',
                            borderRadius: '50%',
                            boxShadow: 'rgba(0, 0, 0, 0.4) 0px 0px 5px 0px',
                            color: 'rgb(0 0 0)',
                            fontSize: '16px',
                            height: '23px',
                            margin: '0px',
                            opacity: '1',
                            outline: '0px',
                            padding: '0px',
                            position: 'absolute',
                            right: '-10px',
                            top: '-10px',
                            width: '23px'
                        }}
                        onClick={handleClose}
                    >
                        <i className="fas fa-times"></i>
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default VideoDisplayModal;
