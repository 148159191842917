import { atom } from 'recoil';

export const audioState = atom({
  key: 'audioState', // unique ID for this atom
  default: {
    isPlaying: false,
    audioSrc: null,
    isMinimized: false,
    isMuted:false,
    currentTime: null,
    videoUrl: null
  },
});

