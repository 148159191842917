import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { WebinarImgUrl } from "../../Constants/Config";
import { FormattedDate2 } from "../../Constants/FormattedDate";
import { GetWebinar } from "../../Redux/UserHome/action";
import WebinarDisplayModal from "../../Components/Modals/WebinarDisplayModal";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import CombinedFooter from "../../Components/Footer/CombinedFooter";
import useHardwareBackButton from "../../Constants/HardwareBackButtonPress";
import { getSession } from "../../lib/session";
import toast from "react-hot-toast";
function WebinarMobile() {
  const dispatch = useDispatch();
  const session=getSession()
  const WebinarData = useSelector((state) => state.UserHomeReducer.GetWebinar);
  const navigate = useNavigate();
  const handleBackButtonPress=()=>{
    navigate('/user-home')
  }
  useHardwareBackButton(0,handleBackButtonPress) 
  useEffect(() => {
    dispatch(
      GetWebinar({
        userId: 41,
      }),
    );
  }, []);

  const sliderSettings = {
    dots: false,
    arrows: true,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnFocus:true,
    pauseOnHover:true,
    
    responsive: [
      {
        breakpoint: 769,
        settings: {
          arrows: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          centerPadding: "0px",
        },
      },
    ],
  };

  //Article show Modal
  const [ShowWebinarModal, setShowWebinarModal] = useState(false);
  const CloseWebinarModal = () => {
    setShowWebinarModal(false);
  };

  const [SelectedWebinarData, setSelectedWebinarData] = useState([]);
  const WebinarClick = (Data) => {
    const isPremiumUser = session.isPremiumUser;
    if (isPremiumUser || Data.IsPremium !== 1) {
      setSelectedWebinarData(Data);
      setShowWebinarModal(true);
    } else {
      toast.error("Please purchase a package");
    }
  };
  return (
    <>
      <WebinarDisplayModal
        ShowWebinarModal={ShowWebinarModal}
        CloseWebinarModal={CloseWebinarModal}
        SelectedWebinarData={SelectedWebinarData}
      />
      <div
        style={{
          height: "100vh",
          width: "100%",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
        }}
        >
        <div className="Article-Modal home-slider ">
          <div className="page-header-con">
            <button className="backbtn" onClick={() => navigate("/user-home")}>
              <i className="fas fa-chevron-left"></i>
            </button>
          </div>
        </div>

        <div className="container" style={{ flex: "1 1 0%" }}>
          {WebinarData?.length > 0 && (
            <div className="col-md-12">
              <div className="webinar-wrapper">
                <h2>Webinar</h2>
                <Slider {...sliderSettings} className="webinar">
                  {WebinarData?.map((data) => (
                    <div
                      className={`carousel-item ${data.IsPremium === 1 ? "premium-icon" : ""} ${data.IsCopyRight === 1 ? "copy-icon" : ""}`}
                      key={data.Id}
                      onClick={() => WebinarClick(data)}
                    >
                      <span className="premium-icon-span">
                        <img src={WebinarImgUrl + data.Image} alt="webinar" />
                      </span>
                      <h5>Starts On : {FormattedDate2(data.StartDate)}</h5>
                      <p>{data.Name}</p>
                      <button
                        style={{
                          marginTop: "4px",
                          background: "#ad7b42",
                          border: "1px solid ghostwhite",
                          color: "white",
                          padding: "5px 10px",
                          fontSize: "12px",
                          borderRadius: "5px",
                        }}
                      >
                        Know more
                      </button>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          )}
            <div className="col-md-12" style={{
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              height:'100%'
            }}>
              <h3>Coming Soon</h3>
            </div>
          
        </div>
        <CombinedFooter />
      </div>
    </>
  );
}

export default WebinarMobile;
