import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import { store } from './Redux/storeConfig/store';
import { BrowserRouter } from 'react-router-dom';
import {
    QueryClient,
    QueryClientProvider
} from '@tanstack/react-query';
import { RecoilRoot } from 'recoil';
import AudioContainer from './Components/Modals/AudioContainer';
import useOnlineStatus from './offline/OnlineStatus';
import OfflineIndicator from './offline/offlineIndicator';

// Create a client for React Query
const queryClient = new QueryClient();

// Main Component to Handle Online/Offline Status
const Main = () => {
    const isOnline = useOnlineStatus();

    return (
        <>
            {!isOnline && <OfflineIndicator />}
            {isOnline && <App />}
        </>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <RecoilRoot>
                <BrowserRouter>
                    <Main />
                </BrowserRouter>
                <ToastContainer />
                <AudioContainer />
            </RecoilRoot>
        </QueryClientProvider>
    </Provider>
);
